import React from "react";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Button } from "react-bootstrap";

export const ProductInfoPopUp = (
  { label, short_name, description },
  showClose,
  closeCallback
) => {
  return (
    <Popover
      id="popover-trigger-hover-focus"
      className="indicator-tooltip"
      title={label ?? short_name}
    >
      <div
        className="popup-title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "7px",
        }}
      >
        {label ?? short_name}
        {showClose && (
          <Button
            variant="outline"
            size="sm"
            style={{ padding: "0px 8px", color: "#005a8c" }}
            onClick={closeCallback}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </div>
      <div className="popup-content">
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </Popover>
  );
};

export default ProductInfoPopUp;
