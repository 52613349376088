import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { ListGroup, Button, OverlayTrigger, Badge } from "react-bootstrap";

import formatDate from "./helpers/formatDate";
import ProductInfoPopUp from "./ProductInfoPopup";

const AccordionProductListItem = ({
  product,
  active,
  selectionCallback,
  disabled,
  willExceedMaxSelection,
  language,
  previousClickedTooltipTarget,
  setPreviousClickedTooltipTarget,
  closeTooltipOnNextClick,
  setCloseTooltipOnNextClick,
}) => {
  const [show, setShow] = useState(false);

  const handleTooltipTrigger = (e) => {
    if (e.type === "click") {
      const nextPopoverState =
        !closeTooltipOnNextClick ||
        previousClickedTooltipTarget !== e.currentTarget.id;
      setCloseTooltipOnNextClick(nextPopoverState);
      setPreviousClickedTooltipTarget(
        nextPopoverState ? e.currentTarget.id : null
      );
    } else if (!previousClickedTooltipTarget) {
      setShow(e.type === "mouseleave" ? false : !show);
    }
  };

  useEffect(() => {
    setShow(
      previousClickedTooltipTarget &&
        previousClickedTooltipTarget === product.name
    );
  }, [previousClickedTooltipTarget]);

  const handleClosePopover = () => {
    setCloseTooltipOnNextClick(false);
    setPreviousClickedTooltipTarget(null);
  };

  return (
    <OverlayTrigger
      show={show}
      placement="right"
      overlay={ProductInfoPopUp(
        product,
        closeTooltipOnNextClick,
        handleClosePopover
      )}
    >
      {({ ref, ...triggerHandler }) => (
        <ListGroup.Item
          key={product.name}
          ref={ref}
          className={`list-group-item ${
            disabled || willExceedMaxSelection ? "disabled" : ""
          }`}
        >
          <div
            className={`card-text-content ${
              language === "ar" ? "custom-rtl-dir" : ""
            }`}
          >
            <div>
              <h4 className="label" aria-describedby="info-tooltip">
                {product.label}
              </h4>
              {product.description && (
                <div
                  {...triggerHandler}
                  onFocus={handleTooltipTrigger}
                  onMouseEnter={handleTooltipTrigger}
                  onMouseLeave={handleTooltipTrigger}
                  onClick={handleTooltipTrigger}
                  aria-label="More info"
                  id={product.name}
                >
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    role="tooltip"
                    id="info-tooltip"
                  />
                </div>
              )}
            </div>
            {product.date_min && (
              <small className="date-range">
                {formatDate(product.date_min)} - {formatDate(product.date_max)}
              </small>
            )}
            {disabled && (
              <Badge className="unavailable-badge">Unavailable</Badge>
            )}
          </div>
          <Button
            className={`card-selection-button${active ? " active" : ""} ${
              disabled || willExceedMaxSelection ? "disabled" : ""
            }`}
            onClick={(e) => !disabled && selectionCallback(e)}
            id={product.name}
          >
            <FontAwesomeIcon icon={active ? faEye : faEyeSlash} />
          </Button>
        </ListGroup.Item>
      )}
    </OverlayTrigger>
  );
};

export default AccordionProductListItem;
