import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { Accordion } from "react-bootstrap";
import OverlayHeaderIcon from "../../assets/images/overlay-icon.svg";
import AccordionProductListItem from "./AccordionProductListItem";
import BaseProductCategoryAccordion from "./BaseProductCategoryAccordion";
import { overlaysRequest, overlaysToggle } from "actions";

const OverlaysAccordion = ({
  products_overlays,
  products_overlays_active,
  previousClickedTooltipTarget,
  setPreviousClickedTooltipTarget,
  closeTooltipOnNextClick,
  setCloseTooltipOnNextClick,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = useI18next();

  useEffect(() => {
    dispatch(overlaysRequest(language));
  }, [dispatch, language]);

  const renderOverlaysTree = (overlaysList) =>
    overlaysList.map((overlay, idx) => (
      <div key={idx}>
        {
          <AccordionProductListItem
            product={overlay}
            active={products_overlays_active.includes(overlay.name)}
            selectionCallback={onOverlayClick}
            previousClickedTooltipTarget={previousClickedTooltipTarget}
            setPreviousClickedTooltipTarget={setPreviousClickedTooltipTarget}
            closeTooltipOnNextClick={closeTooltipOnNextClick}
            setCloseTooltipOnNextClick={setCloseTooltipOnNextClick}
          />
        }
        {overlay.children &&
          !!overlay.children.length &&
          renderOverlaysTree(overlay.children)}
      </div>
    ));

  const onOverlayClick = (e) => {
    if (e.currentTarget.id === products_overlays_active[0]) {
      dispatch(overlaysToggle());
    } else {
      dispatch(overlaysToggle(e.currentTarget.id));
    }
  };

  return products_overlays.length ? (
    <Accordion defaultActiveKey="1">
      <BaseProductCategoryAccordion
        categoryIcon={OverlayHeaderIcon}
        categoryLabel={t("Overlays")}
        selectionsCount={products_overlays_active.length}
        eventKey="0"
      >
        {renderOverlaysTree(products_overlays)}
      </BaseProductCategoryAccordion>
    </Accordion>
  ) : null;
};

export default connect((state) => state)(OverlaysAccordion);
